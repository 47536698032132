import React, { Component } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";
import instance from '../services/baseUrl_service';

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Freelance digital designer',
        title: 'Hello, I’m <span>Nancy</span> Welcome to my World.',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
class PortfolioLanding extends Component {

    state={
        heroBlock:[],
        aboutMe:[],
        service:[],
        project:[]
    }

    componentDidMount(){
        this.heroBloack();
        this.aboutMe();
        this.services();
        this.projects();   
    }

    heroBloack(){
        instance.get("/information/Banner/")
        .then((response) => response.data)
        .then(banner => {
            this.setState({ heroBlock: banner });
        });
    }
    aboutMe(){
        instance.get("/information/AboutMe/")
        .then((response) => response.data)
        .then(banner => {
            this.setState({ aboutMe: banner });
        });
    }
    services(){
        instance.get("/information/Service/")
        .then((response) => response.data)
        .then(services => {
            this.setState({ service: services });
        });
    }
    projects(){
        instance.get("/information/Project/")
        .then((response) => response.data)
        .then(projects => {
            this.setState({ project: projects });
        });
    }
    
    render(){
    const PostList = BlogContent.slice(0 , 3);
        return (
            <div>
                <Helmet pageTitle="AKM RASHID" />
                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
                {/* Start Slider Area   */}
                <div id="home" className="fix">
                    <div className="slider-wrapper">
                        {/* Start Single Slide */}
                        {this.state.heroBlock.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image" style={{backgroundImage: `url("${value.banner_image}")`}} key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="inner text-left">
                                            {value.sub_titel ? <span>{value.sub_titel}</span> : ''}
                                            {value.main_Titel ? <h1 className="title" dangerouslySetInnerHTML={{__html: `Hello, I’m <span>${value.main_Titel}</span> Welcome to my World.`}}></h1> : ''}
                                            {/* {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       ))}
                        {/* End Single Slide */}
                    </div>
                </div>
                {/* End Slider Area   */} 
    
                {/* Start About Area */}
                <div id="about" className="fix">
                    <div className="about-area ptb--120  bg_color--1">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/about-7.jpg" alt="About Images"/>
                                        </div>
                                    </div>
                                    {this.state.aboutMe.map((value , index) => (
                                      <div className="col-lg-7" key={index}>
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{value.aboutme_titel}</h2>
                                                <p className="description">{value.aboutme_description}</p>
                                            </div>
                                            <div className="row mt--30">
                                                <TabTwo tabStyle="tab-style--1" />
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {/* End About Area */}
    
                {/* Start Service Area  */}
                <div id="service" className="fix">
                    <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                        <div className="container">
                            {this.state.service.map((value , index) => (
                                <div className="row" key={index}>
                                    <div className="col-lg-12">
                                        <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                            <h2 className="title">{value.titel}</h2>
                                            <p>{value.sub_titel}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="row creative-service">
                                <div className="col-lg-12">
                                    <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {/* End Service Area  */} 
    
                {/* Start Portfolio Area */}
                <div id="portfolio" className="fix">
                    <div className="portfolio-area ptb--120 bg_color--1">
                        <div className="portfolio-sacousel-inner">
                            <div className="container">
                                {this.state.project.map((value , index) => (
                                    <div className="row" key={index}>
                                        <div className="col-lg-12">
                                            <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                                <h2 className="title">{value.titel}</h2>
                                                <p>{value.sub_titel}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="row">
                                    <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                            <a className="rn-button-style--2 btn-solid" href="/blog"><span>View More</span></a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}
    
                {/* Start Blog Area */}
                {/* <div id="blog" className="fix">
                    <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                        <div className="container">
                            <div className="row align-items-end">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="section-title text-center">
                                        <h2>Latest News</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt--60 mt_sm--40">
                                {PostList.map((value , i ) => (
                                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                                        <div className="blog blog-style--1">
                                            <div className="thumbnail">
                                                <a href="/blog-details">
                                                    <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>
                                                <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>    
                        </div>    
                    </div>
                </div> */}
                {/* End Blog Area */}
    
                {/* Start COntact Area */}
                <div id="contact" className="fix">
                    <div className="rn-contact-area ptb--120 bg_color--1">
                        <ContactOne />
                    </div>
                </div>
                {/* End COntact Area */}
    
                <FooterTwo />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </div>
        )
    }
}

export default PortfolioLanding;
